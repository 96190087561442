<template>
  <div>
    <FormKit
      v-if="!!userProfile?.acceptedTerms"
      type="toggle"
      name="public"
      label="Add to public profile"
      help="Document number and image will not be visible"
      :value="false"
    />
    <div class="font-semibold text-xs mb-1.5">What do you want to name this document?</div>
    <div class="mb-3 flex flex-wrap gap-2">
      <div
        v-for="suggestion of nameSuggestions"
        :key="suggestion"
        class="inline-flex cursor-pointer items-center rounded-full py-1 px-3 text-sm font-medium"
        :class="[
          value.name === suggestion ? 'text-blue-700 bg-blue-200' : 'text-gray-700 bg-gray-200'
        ]"
        @click="setSuggestion(suggestion)"
      >
        {{ suggestion }}
      </div>
    </div>
    <FormKit
      type="text"
      label=""
      ref="nameInput"
      validation="required"
      name="name"
      help="This is how the document will appear in your profile and to anyone you share it with"
      placeholder="Try to be descriptive and concise..."
    ></FormKit>
    <div class="grid grid-cols-2 gap-x-6">
      <FormKit
        type="date"
        name="issueDate"
        label="Issue date (optional)"
        placeholder="Select a date"
      />
      <FormKit
        type="date"
        name="expiryDate"
        label="Expiry date (optional)"
        placeholder="Select a date"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserProfileQuery } from '@/queries/userProfile'
import { ref } from 'vue'

const props = defineProps<{
  value: any
}>()

const { data: userProfile } = useUserProfileQuery()
const nameInput = ref()
const nameSuggestions = ['Reference', 'CV']
const setSuggestion = (suggestion: string) => {
  if (props.value.name !== suggestion) {
    nameInput.value?.node.input(suggestion)
  } else {
    nameInput.value?.node.input('')
  }
}
</script>
