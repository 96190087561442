<template>
  <div>
    <FormKit
      v-if="!!userProfile?.acceptedTerms"
      type="toggle"
      name="public"
      help="Document number and image will not be visible"
      :value="false"
      label="Add to public profile"
      data-test-id="certificate-form-public"
    />
    <FormKit
      ref="repeaterInput"
      id="repeater"
      name="certifications"
      label="Qualifications"
      type="repeater"
      :upControl="false"
      :downControl="false"
      :insertControl="true"
      :removeControl="true"
      :addButton="false"
      #default="{ value: certificationValue, index }: any"
    >
      <CertificateListAutoComplete @blur="updateExpiryDate(index)" />
      <FormKit
        v-if="certificateListData?.get(certificationValue.groupOrCertificateTypeId)?.isGroup"
        :label="`Which ${
          certificateListData.get(certificationValue.groupOrCertificateTypeId)!.name
        } specifically?`"
        name="certificateTypeId"
        @input="updateExpiryDate(index)"
        validation="required"
        placeholder="Please select"
        :options="getDisplayGroupOptions(certificationValue.groupOrCertificateTypeId)"
        type="select"
      />
      <div class="grid grid-cols-2 gap-3">
        <FormKit
          label="Issue date"
          @blur="updateExpiryDate(index)"
          name="issueDate"
          :classes="{ outer: 'mb-1' }"
          type="date"
          validation="required"
        />
        <FormKit
          :classes="{ outer: 'mb-1' }"
          :min="certificationValue.issueDate"
          label="Expiry date"
          name="expiryDate"
          type="date"
        />
      </div>
    </FormKit>
    <InfoNote
      class="mb-3 -mt-2"
      text="Did the scanner miss something? Is the certificate type or issuer not listed?"
      ><a
        target="_blank"
        class="font-medium text-blue-700 underline hover:text-blue-600"
        href="https://www.crewdentials.com/certificate-upload"
        >Let&nbsp;us&nbsp;know</a
      ></InfoNote
    >
    <FormKit
      type="text"
      label="Certificate number"
      name="documentRef"
      data-test-id="certificate-form-documentNumber"
    ></FormKit>
    <IssuerListAutoComplete />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { add, formatDuration } from 'date-fns'
import CertificateListAutoComplete from '@/components/CertificateListAutoComplete.vue'
import { useCertificateListQuery } from '@/queries/certificateList'
import IssuerListAutoComplete from '@/components/IssuerListAutoComplete.vue'
import { onMounted } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'
import { useUserProfileQuery } from '@/queries/userProfile'
import InfoNote from '@/components/InfoNote.vue'
const { data: userProfile } = useUserProfileQuery()

defineProps<{
  value: any
}>()

const queryClient = useQueryClient()

onMounted(async () => {
  await queryClient.fetchQuery({ queryKey: ['certificateList'] })
  repeaterInput.value?.node.value.forEach((...args: any[]) => updateExpiryDate(args[1]))
})

const repeaterInput = ref()

const getDisplayGroupOptions = (groupOrCertificateTypeId: string) => {
  return [...certificateListData.value!.values()]
    .filter(({ displayGroup }) => groupOrCertificateTypeId === displayGroup)
    .map(({ name, id }) => ({ value: id, label: name }))
}

const updateExpiryDate = async (index: number) => {
  const value = repeaterInput.value?.node.value[index]
  const validity = certificateListData.value!.get(
    value.certificateTypeId || value.groupOrCertificateTypeId
  )?.validFor
  if (!validity) {
    repeaterInput.value.node.children[index].context.node.find('expiryDate').props.help = ''
    repeaterInput.value.node.children[index].context.node.find('expiryDate').props.attrs.max =
      '2100-01-01'
    repeaterInput.value.node.children[index].context.node.find('expiryDate').props.validation = ``
    repeaterInput.value.node.children[index].context.node.find(
      'expiryDate'
    ).props.validationVisibility = ``
    return
  }
  repeaterInput.value.node.children[index].context.node.find('expiryDate').props.help =
    `Max ${formatDuration(validity)}`
  repeaterInput.value.node.children[index].context.node.find(
    'expiryDate'
  ).props.validationVisibility = `live`

  if (!!value.issueDate) {
    const maxExpiry = add(new Date(value.issueDate), validity).toISOString().split('T')[0]
    repeaterInput.value.node.children[index].context.node.find('expiryDate').props.attrs.max =
      maxExpiry
    repeaterInput.value.node.children[index].context.node.find('expiryDate').props.validation = [
      ['required'],
      ['date_between', value.issueDate, maxExpiry]
    ]
    if (!value.expiryDate) {
      // Maybe change back to auto update on issue date selection
      repeaterInput.value.node.children[index].context.node.find('expiryDate').input(maxExpiry)
      return
    }
  } else {
    repeaterInput.value.node.children[index].context.node.find('expiryDate').props.validation = [
      [`required`]
    ]
  }
  return
}

const { data: certificateListData } = useCertificateListQuery()
</script>
